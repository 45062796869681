import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: 'System Home' }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: 'Admin Information' }, component: () => import('../views/manager/Admin') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: 'Staff Information' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'password', name: 'Password', meta: { name: 'Change password' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: 'Notice' }, component: () => import('../views/manager/Notice') },
      { path: 'staff', name: 'Staff', meta: { name: 'Staff Information' }, component: () => import('../views/manager/Staff') },
      { path: 'staffPerson', name: 'StaffPerson', meta: { name: 'Staff Information' }, component: () => import('../views/manager/StaffPerson') },
      { path: 'department', name: 'Department', meta: { name: 'Department' }, component: () => import('../views/manager/Department') },
      { path: 'category', name: 'Category', meta: { name: 'Asset Category' }, component: () => import('../views/manager/Category') },
      { path: 'assets', name: 'Assets', meta: { name: 'Asset Manager' }, component: () => import('../views/manager/Assets') },
      { path: 'assetsReceive', name: 'AssetsReceive', meta: { name: 'Asset Receipt' }, component: () => import('../views/manager/AssetsReceive') },
      { path: 'assetsIn', name: 'AssetsIn', meta: { name: 'Asset Storage' }, component: () => import('../views/manager/AssetsIn') },
      { path: 'staffAssets', name: 'StaffAssets', meta: { name: 'Person Asset' }, component: () => import('../views/manager/StaffAssets') },
      { path: 'assetsRepair', name: 'AssetsRepair', meta: { name: 'Asset Repair' }, component: () => import('../views/manager/AssetsRepair') },
      { path: 'container', name: 'Container', meta: { name: 'Container Manager' }, component: () => import('../views/manager/Container') },
      { path: 'patient', name: 'Patient', meta: { name: 'Admin Information' }, component: () => import('../views/manager/Patient') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: '注册' }, component: () => import('../views/Register.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
// router.beforeEach((to ,from, next) => {
//   let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
//   if (to.path === '/') {
//     if (user.role) {
//       if (user.role === 'USER') {
//         next('/front/home')
//       } else {
//         next('/home')
//       }
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
